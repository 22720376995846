import {merge} from 'lodash';
import environmentDefaults from 'src/environments/environment.defaults';

const APP_ENV = 'prod-finizens';

export const environment = merge(environmentDefaults, {
  env: APP_ENV,
  rollbar: {
    environment: APP_ENV
  },
  production: true,
  hostName: 'app.finizens.com',
  apiHost: 'https://api.finizens.com',
  langs: ['es'],
  partner: {
    id: 'finizens',
    isExternal: false,
    appName: 'Finizens',
    name: 'Finizens',
    webUrl: 'https://finizens.com',
    investmentPlan: {
      minimumAmountToBeginToInvest: 1000,
      minimumAmountForNextRebalances: 300,
      amountToBePremium: 100000
    },
    onlyCommercialization: false,
    canFinizensSuggestOptimizationRecommendations: true,
    providesIbanData: false,
    login: {
      provider: 'internal',
      type: 'user/password'
    },
    faqs: {
      url: 'https://ayuda.finizens.com',
    },
    contact: {
      phone: {
        countryPrefix: '0034',
        phoneNumber: '910483004',
        openingSchedule: {
          openingTime: '10:00',
          closingTime: '18:00',
          fromWeekday: 'monday',
          toWeekday: 'friday',
          includesLegalHolidays: false
        },
        callFromAbroad: false
      },
      email: 'ayuda@finizens.com'
    },
    kycPotentialAmountQuestions: true,
    partnerProvidesUserData: false,
    informedSale: false
  },
  fixtures: {
    enabled: false,
    externalLogin: {
      port: null
    }
  },
  analytics: {
    segmentWriteKey: 'lQfvaCbAX4aWKIT23eVbIdcTxcRqPDag',
    customerIoSiteId: 'bde896ba85cf2efe0ed0',
  },
  affiliates: {
    financeAds: {
      enable: false
    },
    facebookPixel: {
      enable: false
    }
  },
  legal: {
    privacyPolicyUrl: 'https://finizens.com/privacidad',
    legalWarningUrl: 'https://finizens.com/legal',
  },
  layout: {
    showFooter: true,
    showHereWeExplainItInDetail: true,
    showLink: false,
    showPoweredBy: false,
    showLogout: true,
    footer: {
      show: true,
      type: 'normal'
    },
  },
  products: {
    investment: {
      name: 'Plan de Inversión',
      productId: 'inversis_investment',
      isManagedInThisEnvironment: true,
      showFeesMenuButton: true,
      productUrl: 'https://www.finizens.com/planes/plan-de-inversion/',
      friendPlan: {
        freeAmountPerCustomerAdded: 12000
      },
      amount: {
        default: {
          minInitial: 1000,
          maxInitial: 10000000,
          minMonthly: 0,
          maxMonthly: 0,
          recommendedInitial: 50000,
          recommendedExtraordinary: null,
          recommendedMonthly: 0
        }
      },
      custodians: {
        name: 'Inversis',
      },
      investmentTransfers: {
        problematicCifs: {
          inversis: 'A83131433',
          selfBank: 'A85597821',
          singularBank: 'A85597821',
        }
      }
    },
    pensionPlan: {
      name: 'Plan de Pensiones',
      productId: 'caser_pension_plan',
      isManagedInThisEnvironment: true,
      showFeesMenuButton: false,
      amount: {
        default: {
          minInitial: 50,
          maxInitial: 10000000,
          minMonthly: 50,
          maxMonthly: 1000000,
          recommendedInitial: 900,
          recommendedExtraordinary: null,
          recommendedMonthly: 50
        }
      },
      custodians: {
        name: 'Caser Pensiones',
        default: {
          deposit: {
            canDisableMonthly: true,
            minimumAmount: 50,
            annualMaximum: 1500 // Máximo anual de un plan de pensiones en España
          },
          limits: {
            lastDayToApplyAChangeOfDebitReceiptInFollowingMonth: 31
          }
        }
      }
    },
    unitLinked: {
      name: 'Plan de Ahorro',
      productId: 'caser_unit_linked',
      isManagedInThisEnvironment: true,
      showFeesMenuButton: false,
      amount: {
        default: {
          minInitial: 50,
          maxInitial: 10000000,
          minMonthly: 50,
          maxMonthly: 1000000,
          recommendedInitial: 5000,
          recommendedExtraordinary: 5000,
          recommendedMonthly: 300,
          insuranceBenefitAmount: 500,
        }
      },
      custodians: {
        name: 'Caser Seguros',
        default: {
          deposit: {
            canDisableMonthly: false,
            minimumAmount: 50,
            annualMaximum: 12000000 // Máximo anual de un UL, basado en que se permiten aportaciones de 1.000.00€ al mes
          },
          limits: {
            lastDayToApplyAChangeOfDebitReceiptInFollowingMonth: 14
          }
        }
      }
    },
  },
  fees: {
    feeReduction: 0.14
  },
  shareScreen: {
    enable: false,
    groupid: null,
    site: null
  },
  welcomeUrl: null,
  allowedPhonePrefixes: [
    {country: 'Alemania', prefix: '49'},
    {country: 'Andorra', prefix: '376'},
    {country: 'Argentina', prefix: '54'},
    {country: 'Australia', prefix: '61'},
    {country: 'Austria', prefix: '43'},
    {country: 'B\u00e9lgica', prefix: '32'},
    {country: 'Brasil', prefix: '55'},
    {country: 'Bulgaria', prefix: '359'},
    {country: 'Canad\u00e1', prefix: '1'},
    {country: 'Chile', prefix: '56'},
    {country: 'Corea del Sur', prefix: '82'},
    {country: 'Costa Rica', prefix: '506'},
    {country: 'Croacia', prefix: '385'},
    {country: 'Dinamarca', prefix: '45'},
    {country: 'Ecuador', prefix: '593'},
    {country: 'Rep\u00fablica Eslovaca', prefix: '421'},
    {country: 'Eslovenia', prefix: '386'},
    {country: 'Espa\u00f1a', prefix: '34'},
    {country: 'Estados Unidos', prefix: '1'},
    {country: 'Estonia', prefix: '372'},
    {country: 'Finlandia', prefix: '358'},
    {country: 'Francia', prefix: '33'},
    {country: 'Georgia', prefix: '995'},
    {country: 'Grecia', prefix: '30'},
    {country: 'Groenlandia', prefix: '299'},
    {country: 'Honduras', prefix: '504'},
    {country: 'Hong Kong', prefix: '852'},
    {country: 'Hungr\u00eda', prefix: '36'},
    {country: 'India', prefix: '91'},
    {country: 'Irlanda', prefix: '353'},
    {country: 'Isla de Navidad', prefix: '61'},
    {country: 'Isla de Norfolk', prefix: '672'},
    {country: 'Islandia', prefix: '354'},
    {country: 'Islas Cocos o Islas Keeling', prefix: '61'},
    {country: 'Islas de Aland', prefix: '358'},
    {country: 'Islas Faroe', prefix: '298'},
    {country: 'Islas Georgias del Sur y Sandwich del Sur', prefix: '500'},
    {country: 'Islas Ultramarinas Menores de Estados Unidos', prefix: '246'},
    {country: 'Italia', prefix: '39'},
    {country: 'Jap\u00f3n', prefix: '81'},
    {country: 'Letonia', prefix: '371'},
    {country: 'Lituania', prefix: '370'},
    {country: 'Macedonia', prefix: '389'},
    {country: 'Malasia', prefix: '60'},
    {country: 'Mayotte', prefix: '262'},
    {country: 'M\u00e9xico', prefix: '52'},
    {country: 'Moldavia', prefix: '373'},
    {country: 'Montenegro', prefix: '382'},
    {country: 'Noruega', prefix: '47'},
    {country: 'Nueva Caledonia', prefix: '687'},
    {country: 'Nueva Zelanda', prefix: '64'},
    {country: 'Pa\u00edses Bajos', prefix: '31'},
    {country: 'Paraguay', prefix: '595'},
    {country: 'Polinesia Francesa', prefix: '689'},
    {country: 'Polonia', prefix: '48'},
    {country: 'Portugal', prefix: '351'},
    {country: 'Puerto Rico', prefix: '1'},
    {country: 'Reino Unido', prefix: '44'},
    {country: 'Chequia', prefix: '420'},
    {country: 'Rep\u00fablica Dominicana', prefix: '1809'},
    {country: 'San Bartolom\u00e9', prefix: '590'},
    {country: 'San Pedro y Miquel\u00f3n', prefix: '508'},
    {country: 'Rep\u00fablica de Sud\u00e1frica', prefix: '27'},
    {country: 'Suecia', prefix: '46'},
    {country: 'Suiza', prefix: '41'},
    {country: 'Islas Svalbard y Jan Mayen', prefix: '47'},
    {country: 'Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico', prefix: '246'},
    {country: 'Tierras Australes y Ant\u00e1rticas Francesas', prefix: '33'},
    {country: 'Islas Tokelau', prefix: '690'},
    {country: 'Uruguay', prefix: '598'}
  ]
});
